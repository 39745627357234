import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';

const ArticlesPage = () => {
  return (
    <Layout>
      <h1>Articles</h1>
    </Layout>
  );
};

export default ArticlesPage;
